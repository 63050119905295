import { useEffect, useRef, useState } from 'react'

import './App.css'

const isNumeric = num => /^-?[0-9]+(?:\.[0-9]+)?$/.test(num + '');

function resetTotals() {
  return {
    x: 0,
    y: 0,
    z: 0,
    sum: 0
  }
}

function App() {

  function toggleDeviceMotion() {
    if (shakeEnabled) {
      console.log('toggleDeviceMotion()', 'setShakeEnabled(false)')
      hasListener.current = false
      setShakeEnabled(false)
    } else {
      if (typeof DeviceMotionEvent.requestPermission === 'function') {
        DeviceMotionEvent.requestPermission()
          .then(permissionState => {
            if (permissionState === 'granted') {
              setHasPermission(true)
              setShakeEnabled(true)
            }
          })
          .catch(console.error);
      } else {
        setHasPermission(true)
        setShakeEnabled(true)
      }
    }
  }

  function handleMotionEvent(event) {

    if (!hasListener.current) return

    if (isNaN(Math.abs(parseFloat(event.accelerationIncludingGravity.x)))) return

    const newX = Math.abs(parseFloat(event.accelerationIncludingGravity.x))
    const newY = Math.abs(parseFloat(event.accelerationIncludingGravity.y))

    const deltaX = Math.abs(x.current - newX)
    const deltaY = Math.abs(y.current - newY)
    const sum = deltaX + deltaY

    x.current = newX
    y.current = newY

    totals.current = {
      x: parseFloat(totals.current.x) + newX,
      y: parseFloat(totals.current.y) + newY,
      sum: parseFloat(totals.current.sum) + sum
    }

    setStateX(newX.toFixed(1))
    setStateY(newY.toFixed(1))

  }

  const x = useRef(0)
  const y = useRef(0)
  const totals = useRef(resetTotals());
  const hasListener = useRef(false)

  const [stateX, setStateX] = useState('--')
  const [stateY, setStateY] = useState('--')
  const [hasPermission, setHasPermission] = useState(false)
  const [shakeEnabled, setShakeEnabled] = useState(false)

  useEffect(() => {
 
    // shake
    if (hasPermission) {

      if (!shakeEnabled) {

        if (hasListener.current) {
          window.removeEventListener('devicemotion', handleMotionEvent)
          hasListener.current = false
          setStateX('--')
          setStateY('--')
        }

      } else {

        if (!hasListener.current) {

          // clear totals
          totals.current = resetTotals()

          // register event listener
          hasListener.current = true

          console.log('add event listener')
          window.addEventListener('devicemotion', (event) => {
            handleMotionEvent(event)
          });

          // setup automatic event listener deregistration
          let limit = 3
          const countdown = setInterval(() => {
            limit--
            if (limit === 0 || !hasListener.current) {
              alert('All done!')
              limit = 0
              clearInterval(countdown)
              setShakeEnabled(false)
            }
          }, 1000)

        }

      }

    }
 
  }, [hasListener, shakeEnabled]);

  return (
    <div className="App">
      <div className="container">

        <h1>Device Motion</h1>
        <b>Tracked Position</b><br />
        {stateX}<br />
        {stateY}<br />
        <br />

        <b>Tracked Movement</b> (summed deltas)<br />
        {totals.current.sum.toFixed(1)}<br />
        <br />

        <b>Debugging</b><br />
        hasPermission: {hasPermission.toString()}<br />
        shakeEnabled: {shakeEnabled.toString()}<br />
        <br />

        <button onClick={toggleDeviceMotion}>{shakeEnabled ? 'STOP' : 'TRACK ME'}</button>
      </div>
    </div>
  );

}

export default App;
